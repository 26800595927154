import { TableServiceBase } from './serviceBase';

export class SubjectService extends TableServiceBase<
    Cbas.Models.DTO.SubjectResponse,
    Cbas.Models.Subject,
    Cbas.Models.Subject>{
    constructor() {
        super('Subjects');
    }

    async getLists(): Promise<Cbas.Models.DTO.SubjectResponse[]> {
        const response = await this.getBase('list');
        return response.data;
    }
}
