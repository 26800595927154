import React from 'react';

import { useAppLoadingDisplaySelector } from '@store/selectors';

import styles from './index.less';

export const Loading: React.FC = () => {
    return (
        <div className={styles.loaderSvg} >
            <img alt="loading..."
                src="images/ajax-loader.gif"
            />
        </div>
    );
};

const Loader: React.FC = () => {
    var loadingDisplay = useAppLoadingDisplaySelector();
    return (
        <div id="loader"
            style={{ display: loadingDisplay }}
        >
            <Loading />
        </div>
    );
};

export default Loader;
