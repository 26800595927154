import { ServiceBase } from './serviceBase';

export class CommentService extends ServiceBase {
    constructor() {
        super('Comments');
    }

    async getList(toId?: number, fromId?: number, lessonId?: number): Promise<Cbas.Models.DTO.CommentResponse[]> {
        const response = await this.getBase(`?toId=${toId}&fromId=${fromId}&lessonId=${lessonId}`);
        return response.data;
    }

    async edit(model: any): Promise<boolean> {
        const response = await this.postBase('edit', model);
        return response.success;
    }

    async delete(id: number): Promise<boolean> {
        const response = await this.postBase(`delete/${id}`, undefined);
        return response.success;
    }
}
