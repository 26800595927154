import React from 'react';
import classnames from 'classnames';
import { Loader } from '..';
import styles from './index.less';

export interface PageProps {
    children: React.ReactNode;
    className?: string;
    loading?: boolean;
    inner?: boolean;
}

const Page: React.FC<PageProps> = ({ children, className, loading = false, inner = false }) => {
    const loadingStyle = {
        height: 'calc(100vh - 184px)',
        overflow: 'hidden'
    };
    return (
        <div
            className={classnames(className, {
                [styles.contentInner]: inner
            })}
            style={loading ? loadingStyle : {}}
        >
            {loading ? <Loader /> : ''}
            {children}
        </div>
    );
};

export default Page;
