import { ServiceBase } from './serviceBase';

export class AppointmentService extends ServiceBase {
    constructor() {
        super('Appointments');
    }

    async create(model: Cbas.Models.CustomerAppointment): Promise<boolean> {
        const response = await this.postBase('create', model);
        return response.success;
    }
    async getAll(customerId: number): Promise<Cbas.Models.DTO.CustomerAppointmentResponse[]> {
        const response = await this.getBase(customerId);
        return response.data;
    }
}
