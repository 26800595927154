import axios, { AxiosRequestConfig } from 'axios';
import { notification, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

import { store } from '@store';
import { toggleLoadingAction } from '@store/actions';
import { storageManager } from '@utils/storage';
import { TICKET } from '@models/common';
import React from 'react';

let _token: Cbas.Models.DTO.AuthResponse | undefined;

export const registerTicket = (token?: Cbas.Models.DTO.AuthResponse) => {
    _token = token;
};

axios.defaults.baseURL = '/api';

axios.interceptors.request.use(
    config => {
        store.dispatch(toggleLoadingAction('block'));
        if (config.headers && _token) {
            config.headers.Authorization = _token.tokenType + ' ' + _token.accessToken;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        store.dispatch(toggleLoadingAction('none'));
        if (response.data.success === false) {
            notification.warning({
                message: 'Notification',
                description: response.data.message
            });
        }
        return response;
    },
    error => {
        if (error.response.status === 401) {
            storageManager.remove(TICKET);
            registerTicket();
            //refresh to redirect to login
            window.location.href = '/';
        } else {
            notification.error({
                message: 'Network Error',
                description:
                    'Something unhandled, please retry, refresh your browser if needed.'
            });
        }
        return Promise.reject(error);
    }
);

const getPageParams = (
    pagination?: TablePaginationConfig,
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<any> | SorterResult<any>[],
    extra?: TableCurrentDataSource<any>
) => {
    const params: any = {
        pageSize: pagination?.pageSize,
        page: pagination?.current,
        ...filters,
        ...sorter
    };
    return params;
};

export interface IGetPage<T> {
    getPage(
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<any> | SorterResult<any>[],
        extra?: TableCurrentDataSource<any>
    ): Promise<Cbas.Models.Pagination.PagedResult<T>>;
}

export abstract class ServiceBase {
    private readonly httpCache = new Map<React.Key, Cbas.Models.DTO.HTTPResponse>();
    constructor(protected controllerName: string) { }

    protected async getBase(url: React.Key = ''): Promise<Cbas.Models.DTO.HTTPResponse> {
        const axiosResponse = await axios.get<Cbas.Models.DTO.HTTPResponse>(`${this.controllerName}/${url}`);
        return axiosResponse.data;
    }

    protected async getBaseWithCache(url: React.Key = '', force: boolean = false, inController: boolean = true, httpConfig?: AxiosRequestConfig<any>): Promise<Cbas.Models.DTO.HTTPResponse> {
        const finalUrl = inController ? `${this.controllerName}/${url}` : `${url}`;
        if (this.httpCache.size > 999) {
            this.httpCache.clear();
        } else {
            if (!force && this.httpCache.has(finalUrl)) {
                return this.httpCache.get(finalUrl)!;
            }
        }
        const axiosResponse = await axios.get<Cbas.Models.DTO.HTTPResponse>(finalUrl, httpConfig);
        this.httpCache.set(finalUrl, axiosResponse.data);
        return axiosResponse.data;
    }

    protected async getListBase<T>(url: React.Key = '', filter: T): Promise<Cbas.Models.DTO.HTTPResponse> {
        const axiosResponse = await axios.get<Cbas.Models.DTO.HTTPResponse>(`${this.controllerName}/${url}`, { params: filter });
        return axiosResponse.data;
    }

    protected async postBase<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<Cbas.Models.DTO.HTTPResponse> {
        const axiosResponse = await axios.post<Cbas.Models.DTO.HTTPResponse>(`${this.controllerName}/${url}`, data, config);
        return axiosResponse.data;
    }

    protected async getPageBase(
        url: string,
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<any> | SorterResult<any>[],
        extra?: TableCurrentDataSource<any>
    ): Promise<Cbas.Models.DTO.HTTPResponse> {
        const axiosResponse = await axios.request<Cbas.Models.DTO.HTTPResponse>({
            url: `${this.controllerName}/${url}`,
            method: 'get',
            params: getPageParams(pagination, filters, sorter, extra)
        });
        return axiosResponse.data;
    }

    protected removeGetCache(url: React.Key = '', inController: boolean = true): void {
        const finalUrl = inController ? `${this.controllerName}/${url}` : `${url}`;
        this.httpCache.delete(finalUrl);
    }
}

export class TableServiceBase<ListModel, CreateModel, UpdateModel> extends ServiceBase implements IGetPage<ListModel>{
    async getPage(
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<any> | SorterResult<any>[],
        extra?: TableCurrentDataSource<any>
    ): Promise<Cbas.Models.Pagination.PagedResult<ListModel>> {
        const response = await this.getPageBase('', pagination, filters, sorter, extra);
        return response.data;
    }

    async get<T>(id: number, force: boolean = false): Promise<T> {
        const response = await this.getBaseWithCache(id, force);
        return response.data;
    }
    removeItemCache(id: number): void {
        this.removeGetCache(id);
    }

    async create(model: CreateModel): Promise<boolean> {
        const response = await this.postBase('create', model);
        return response.success;
    }

    async edit(model: UpdateModel): Promise<boolean> {
        const response = await this.postBase('edit', model);
        return response.success;
    }

    async delete(id: number): Promise<boolean> {
        const response = await this.postBase(`delete/${id}`, undefined);
        return response.success;
    }
}
