import React, { useEffect, useMemo, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import './app.less';
import UserContext from './context';
import AppRoutes from './app.routes';

import { useAppLanguageSelector } from '@store/selectors';
import { Loader } from '@components';

import { currentAntdLocale, setLanguage } from '@utils/languages';
import { storageManager } from '@utils/storage';
import { TICKET } from '@models/common';
import { registerTicket } from '@services/serviceBase';
import { UserInfo } from '@models/userInfo';
import { getUser } from '@utils/permission';

const App: React.FC = () => {
  const language = useAppLanguageSelector();
  const [user, setUser] = useState<UserInfo>();

  useEffect(() => {
    document.getElementById('indexLoader')!.style.display = 'none';
    var tickit = storageManager.get<Cbas.Models.DTO.LoginResponse>(TICKET);
    if (tickit) {
      registerTicket(tickit.token);
      setUser(getUser(tickit.user));
    }
  }, []);

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  return useMemo(() =>
    <UserContext.Provider value={{ user, setUser }}>
      <ConfigProvider locale={currentAntdLocale}>
        <HashRouter>
          <Loader />
          <AppRoutes />
        </HashRouter>
      </ConfigProvider>
    </UserContext.Provider>, [user]);
};

export default App;
