import { TableServiceBase } from './serviceBase';

export class StaffService extends TableServiceBase<
    Cbas.Models.DTO.StaffResponse,
    any,
    any>{
    constructor() {
        super('Staffs');
    }
    async getStaffs(role: number): Promise<Cbas.Models.DTO.StaffResponse[]> {
        const response = await this.getBase(`list?role=${role}`);
        return response.data;
    }

    async getRoles(): Promise<Cbas.Models.DTO.RoleResponse[]> {
        const response = await this.getBase('roles');
        return response.data;
    }
}
