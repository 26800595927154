import { TableServiceBase } from './serviceBase';

export class SemesterService extends TableServiceBase<
    Cbas.Models.DTO.ContractListResponse,
    Cbas.Models.DTO.SemesterRequest,
    Cbas.Models.DTO.SemesterRequest> {
    constructor() {
        super('Semesters');
    }

    async getSemesterReportsByCustomerId(customerId: number): Promise<Cbas.Models.DTO.SemesterResponse[]> {
        const response = await this.getBase(`customer/${customerId}`);
        return response.data;
    }

    async getAssessmentScoreList(): Promise<Cbas.Models.DTO.SemesterAssessmentResponse[]> {
        const response = await this.getBase('assessments');
        return response.data;
    }

    async getSemesterFormTemplateByCustomerId(semesterCategoryId: number, customerId: number, force: boolean = false): Promise<Cbas.Models.DTO.SemesterResponse> {
        const response = await this.getBaseWithCache(`form/semester/${semesterCategoryId}/customer/${customerId}`, force);
        return response.data;
    }
    removeSemesterFormTemplateCache(semesterCategoryId: number, customerId: number): void {
        this.removeGetCache(`form/semester/${semesterCategoryId}/customer/${customerId}`);
    }
}
