import { ActionInfo, IAState } from '../models';

export const iaInitialState: IAState = {};

export function iaReducer(state: IAState = iaInitialState, action: ActionInfo): IAState {
    const { value } = action;
    switch (action.type) {
        case 'parentSurveyQuestions':
            return { ...state, parentSurveyQuestions: value };
        case 'parentAptitudeQuestions':
            return { ...state, parentAptitudeQuestions: value };
        default:
            return state;
    }
}
