import { useSelector } from 'react-redux';
import { CbasState } from '../models';

export const useLanguageDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.languageDdl);

export const useCountryDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.countryDdl);

export const useRoleDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.roleDdl);

export const useChannelCategoryDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.channelCategoryDdl);

export const useTeacherDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.teacherDdl);

export const useStudioDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.studioDdl);

export const useLevelDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.levelDdl);

export const useSubjectDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.subjectDdl);

export const useContractPaymentCurrencysDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.contractPaymentCurrencysDdl);

export const useContractPaymentMethodsDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.contractPaymentMethodsDdl);

export const useContractTypesDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.contractTypesDdl);

export const useLessonCodeDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.lessonCodeDdl);

export const useAssessmentScoreDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.assessmentScoreDdl);

export const useContractCancelationReasonDdlSelector = () =>
    useSelector((state: CbasState) => state.ddlState.contractCancelationReasonDdl);
