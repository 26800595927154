import { TableServiceBase } from './serviceBase';

export class LevelService extends TableServiceBase<
    any,
    any,
    any>{
    constructor() {
        super('Levels');
    }

    async getLevels(): Promise<Cbas.Models.DTO.LevelResponse[]> {
        const response = await this.getBase('list');
        return response.data;
    }
}
