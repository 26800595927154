import { ServiceBase } from './serviceBase';

export class DashboardService extends ServiceBase {
    constructor() {
        super('Dashboard');
    }

    async getCustomerStats(): Promise<Cbas.Models.DTO.Dashboard.CustomerStatsResponse[]> {
        const response = await this.getBase('customer/stats');
        return response.data;
    }
    async getMiscStats(): Promise<Cbas.Models.DTO.Dashboard.MiscStatsResponse[]> {
        const response = await this.getBase('misc/stats');
        return response.data;
    }
    async getStudentStatusStats(): Promise<Cbas.Models.DTO.Dashboard.MiscStatsResponse[]> {
        const response = await this.getBase('customer/studentstatus');
        return response.data;
    }
}
