
export interface MapModel<K = number> {
    key: K;
    value: string;
}

export const ageRange: MapModel<string>[] = [
    { key: '4-5', value: '4-5' },
    { key: '5-6', value: '5-6' },
    { key: '6-7', value: '6-7' },
    { key: '7-8', value: '7-8' },
    { key: '8-9', value: '8-9' },
    { key: '9-10', value: '9-10' },
    { key: '10-11', value: '10-11' },
    { key: '11-12', value: '11-12' },
    { key: '13+', value: '13+' }
];

export const TICKET = 'ticket';
