import { useSelector } from 'react-redux';
import { CbasState } from '../models';

export const useAppSelector = () => useSelector((state: CbasState) => state.appState);

export const useAppLanguageSelector = () => useSelector((state: CbasState) => state.appState.language);

export const useAppLoadingDisplaySelector = () => useSelector((state: CbasState) => state.appState.loadingDisplay);

export const useAppMmenuCollapsedSelector = () => useSelector((state: CbasState) => state.appState.menuCollapsed);

export const useAppDownloadingSelector = () => useSelector((state: CbasState) => state.appState.downloading);
