import { TableServiceBase } from './serviceBase';

export class LessonCodeService extends TableServiceBase<
    Cbas.Models.DTO.LessonResponse,
    any,
    any> {
    constructor() {
        super('LessonCodes');
    }

    async getList(courseCategory: number | undefined, level: number | undefined, subject: number | undefined): Promise<Cbas.Models.DTO.LessonReviewResponse> {
        const response = await this.getBase(`?courseCategoryId=${courseCategory}&levelId=${level}&subjectId=${subject}`);
        return response.data;
    }
}
