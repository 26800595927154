import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import reportWebVitals from './reportWebVitals';

import './index.less';
import App from './app';

import { store } from '@store';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </Provider>
  </HelmetProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
