import React from 'react';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const ComingSoon: React.FC = () => {
    return (
        <Result
            icon={<SmileOutlined />}
            title="Coming Soon"
        />
    );
};

export default ComingSoon;
