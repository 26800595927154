import React, { Fragment, MouseEventHandler } from 'react';
import { Avatar, Col, Form, FormInstance, Input, Layout, Menu, message, Modal, Row } from 'antd';
import { useNavigate } from 'react-router';
import { t, Trans } from '@lingui/macro';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import classnames from 'classnames';

import styles from './index.less';

import { useCbasDispatch } from '@store';
import { toggleLanguageAction, toggleMenuAction } from '@store/actions';
import { useAppLanguageSelector, useAppMmenuCollapsedSelector } from '@store/selectors';
import { languages } from '@utils/languages';
import { TICKET } from '@models/common';
import { registerTicket } from '@services/serviceBase';
import { storageManager } from '@utils/storage';
import { accountService } from '@services';

// const notifications = [
//     {
//         title: 'New User is registered.',
//         date: new Date(Date.now() - 10000000)
//     },
//     {
//         title: 'Application has been approved.',
//         date: new Date(Date.now() - 50000000)
//     },
//     {
//         title: 'Title Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long.',
//         date: new Date(Date.now() - 50000000)
//     }
// ];

export interface HeaderProps {
    fixed: boolean;
    user?: object;
    menus?: Array<any>;
    onSignOut?: Function;
    notifications?: Array<any>;
    onCollapseChange?: Function;
    onAllNotificationsRead?: MouseEventHandler<HTMLDivElement>;
}
//avatar,username,collapsed,
const Header: React.FC<HeaderProps> = ({ fixed, onCollapseChange, onAllNotificationsRead }: HeaderProps) => {
    const dispatch = useCbasDispatch();
    const mmenuCollapsed = useAppMmenuCollapsedSelector();
    const language = useAppLanguageSelector();
    const navigate = useNavigate();
    const ticket = storageManager.get<Cbas.Models.DTO.LoginResponse>(TICKET);
    const userName = ticket?.user?.fullName;
    const avatar = '/images/icon.png';

    const formRef = React.createRef<FormInstance<any>>();
    const [passwordModalVisible, setPasswordModalVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const signOut = () => {
        storageManager.remove(TICKET);
        registerTicket();
        navigate('/login');
    };

    const handleChangePassword = async () => {
        const values = await formRef.current?.validateFields();
        if (values.newPassword !== values.confirmPassword) {
            message.error(t`Confirm Password must same as New Password!`);
            return;
        }
        if (values) {
            setConfirmLoading(true);
            const result = await accountService.changePassword({
                ...values,
                staffId: ticket?.user?.id
            });
            if (result) {
                message.success('OK');
                setPasswordModalVisible(false);
                signOut();
            }
            else {
                message.error('Faild');
            }
            setConfirmLoading(false);
        }
    };

    const rightContent = [
        <Fragment key="user">
            <Menu
                mode="horizontal"
            >
                <Menu.SubMenu
                    key="userSub"
                    title={
                        <Fragment>
                            <span style={{ color: '#999', marginRight: 4 }}>
                                <Trans>Hi,</Trans>
                            </span>
                            <span>{userName}</span>
                            <Avatar alt="User"
                                className={styles.userAvatar}
                                shape="square"
                                src={avatar}
                                style={{ marginLeft: 8 }}
                            />
                        </Fragment>
                    }
                >
                    <Menu.Item key="ChangePass"
                        onClick={() => {
                            setPasswordModalVisible(true);
                        }}
                    >
                        <Trans>Change Password</Trans>
                    </Menu.Item>
                    <Menu.Item key="SignOut"
                        onClick={signOut}
                    >
                        <Trans>Sign out</Trans>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu >
            <Modal
                confirmLoading={confirmLoading}
                onCancel={() => setPasswordModalVisible(false)}
                onOk={handleChangePassword}
                title={<Trans>Change Password</Trans>}
                visible={passwordModalVisible}
            >
                <Form
                    layout="vertical"
                    ref={formRef}
                >
                    <Form.Item
                        hidden
                        name="staffId"
                    >
                        < Input />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="New Password"
                                name="newPassword"
                                rules={[{ required: true }]}
                            >
                                < Input type="password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[{ required: true }]}
                            >
                                < Input type="password" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Fragment>
    ];

    const currentLanguage = languages.get(language);

    rightContent.unshift(
        <Menu
            key="language"
            mode="horizontal"
            onClick={data => dispatch(toggleLanguageAction(data.key))}
            selectedKeys={[language]}
        >
            <Menu.SubMenu key="languageSub"
                title={
                    <Avatar
                        size="small"
                        src={currentLanguage!.flag}
                    />
                }
            >
                {Array.from(languages).map(([key, lang]) => (
                    <Menu.Item key={key}>
                        <Avatar
                            size="small"
                            src={lang.flag}
                            style={{ marginRight: 8 }}
                        />
                        {lang.title}
                    </Menu.Item>
                ))}
            </Menu.SubMenu>
        </Menu>
    );

    // rightContent.unshift(
    //     <Popover
    //         placement="bottomRight"
    //         trigger="click"
    //         key="notifications"
    //         overlayClassName={styles.notificationPopover}
    //         getPopupContainer={() => document.querySelector('#primaryLayout')!}
    //         content={
    //             <div className={styles.notification}>
    //                 <List<any>
    //                     itemLayout="horizontal"
    //                     dataSource={notifications}
    //                     locale={{
    //                         emptyText: <Trans>You have viewed all notifications.</Trans>,
    //                     }}
    //                     renderItem={item => (
    //                         <List.Item className={styles.notificationItem}>
    //                             <List.Item.Meta
    //                                 title={
    //                                     <Ellipsis tooltip lines={1}>
    //                                         {item.title}
    //                                     </Ellipsis>
    //                                 }
    //                                 description={moment(item.date).fromNow()}
    //                             />
    //                             <RightOutlined style={{ fontSize: 10, color: '#ccc' }} />
    //                         </List.Item>
    //                     )}
    //                 />
    //                 {notifications.length ? (
    //                     <div
    //                         onClick={onAllNotificationsRead}
    //                         className={styles.clearButton}
    //                     >
    //                         <Trans>Clear notifications</Trans>
    //                     </div>
    //                 ) : null}
    //             </div>
    //         }
    //     >
    //         <Badge
    //             count={notifications.length}
    //             dot
    //             offset={[-10, 10]}
    //             className={styles.iconButton}
    //         >
    //             <BellOutlined className={styles.iconFont} />
    //         </Badge>
    //     </Popover>
    // )

    return (
        <Layout.Header className={classnames(styles.header, {
            [styles.fixed]: fixed,
            [styles.collapsed]: mmenuCollapsed
        })}
            id="layoutHeader"
        >
            <div
                className={styles.button}
                onClick={() => dispatch(toggleMenuAction(!mmenuCollapsed))}
            >
                {mmenuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div className={styles.rightContainer}>{rightContent}</div>
        </Layout.Header >
    );
};

export default Header;
