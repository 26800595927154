import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

import { CustomerStatus } from '@models/customer';

import { IGetPage, ServiceBase, TableServiceBase } from './serviceBase';

class CustomerStatusService extends ServiceBase implements IGetPage<Cbas.Models.DTO.CustomerListResponse>{
    constructor(private status: CustomerStatus) {
        super('Customers');
    }

    async getPage(
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<any> | SorterResult<any>[],
        extra?: TableCurrentDataSource<any>
    ): Promise<Cbas.Models.Pagination.PagedResult<Cbas.Models.DTO.CustomerListResponse>> {
        const response = await super.getPageBase(this.status, pagination, filters, sorter, extra);
        return response.data;
    }
}

export class CustomerService extends TableServiceBase<
    Cbas.Models.DTO.CustomerListResponse,
    any,
    any>{
    constructor() {
        super('Customers');
        this.lead = new CustomerStatusService('lead');
        this.appointment = new CustomerStatusService('appointment');
        this.assessment = new CustomerStatusService('assessment');
        this.student = new CustomerStatusService('student');
    }
    public lead: CustomerStatusService;
    public appointment: CustomerStatusService;
    public assessment: CustomerStatusService;
    public student: CustomerStatusService;

    async assign(model: any) {
        const response = await this.postBase('assign', model);
        return response.success;
    }

    async collect(model: any) {
        const response = await this.postBase('collect', model);
        return response.success;
    }

    async orientation(model: any) {
        const response = await this.postBase('orientation', model);
        return response.success;
    }

    async bulkCreate(model: any) {
        const response = await this.postBase('create/bulk', model);
        return response.success;
    }
}


