
class StorageManager {
    get<T = string>(key: string): T | null {
        const value = this.getImpl(key);
        if (value) {
            try {
                return JSON.parse(value);
            } catch (_) {
                return value;
            }
        }
        return null;
    }

    set<T = string>(key: string, value: T): void {
        this.setImpl(key, value);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    private getImpl(key: string): any | null {
        return localStorage.getItem(key);
    }

    private setImpl(key: string, value: any): void {
        switch (typeof value) {
            case 'object':
                localStorage.setItem(key, JSON.stringify(value));
                break;
            case 'string':
                localStorage.setItem(key, value);
                break;
        }
    }
}

export const storageManager = new StorageManager();
