import { TableServiceBase } from './serviceBase';

export class LessonService extends TableServiceBase<
    Cbas.Models.DTO.LessonResponse,
    any,
    any> {
    constructor() {
        super('Lessons');
    }

    async getLessons(filter: any): Promise<Cbas.Models.DTO.LessonResponse[]> {
        const response = await this.getListBase('', filter);
        return response.data;
    }

    async getLessonReview(lessonId: number, customerId: number, force: boolean = false): Promise<Cbas.Models.DTO.LessonReviewResponse> {
        const response = await this.getBaseWithCache(`review/${lessonId}/${customerId}`, force);
        return response.data;
    }
    removeLessonReviewCache(lessonId: number, customerId: number): void {
        this.removeGetCache(`review/${lessonId}/${customerId}`);
    }

    async createLessonReview(model: Cbas.Models.DTO.LessonReviewRequest): Promise<boolean> {
        const response = await this.postBase('review/create', model);
        return response.success;
    }
}
