import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { customerService, subjectService } from '@services';
import { IGetPage } from '@services/serviceBase';

import { CbasAction } from '../models';
import { CustomerStatus } from '@models/customer';

interface FetchArgs {
    pagination?: TablePaginationConfig;
    filters?: Record<string, FilterValue | null>;
    sorter?: SorterResult<any> | SorterResult<any>[];
    extra?: TableCurrentDataSource<any>;
}

interface FullFetchArgs<T> extends FetchArgs {
    service: IGetPage<T>,
    type: string,
}

const fetchPageDataAction = <T>({ service, type, pagination, filters, sorter, extra }: FullFetchArgs<T>): CbasAction => {
    return async (dispatch) => {
        const pageData = await service.getPage(pagination, filters, sorter, extra);
        dispatch({ type, value: pageData });
    };
};

export const fetchCustomerPageDataAction = (
    status: CustomerStatus,
    args?: FetchArgs): CbasAction => {
    return fetchPageDataAction({
        ...args,
        service: customerService[status],
        type: 'customerPageData'
    });
};

export const fetchSubjectPageDataAction = (args?: FetchArgs): CbasAction => {
    return fetchPageDataAction({
        ...args,
        service: subjectService,
        type: 'subjectPageData'
    });
};
