import { CampWeekData } from '@models/week';
import { TableServiceBase } from './serviceBase';

export class CampService extends TableServiceBase<
    Cbas.Models.DTO.ContractListResponse,
    Cbas.Models.DTO.CampRequest,
    Cbas.Models.DTO.CampRequest> {
    constructor() {
        super('Camps');
    }

    async getCampReportsByCustomerId(customerId: number): Promise<Cbas.Models.DTO.CampResponse[]> {
        const response = await this.getBase(`customer/${customerId}`);
        return response.data;
    }

    async getCampFormTemplateByCustomerId(campCategoryId: number, customerId: number, force: boolean = false): Promise<Cbas.Models.DTO.CampResponse> {
        const response = await this.getBaseWithCache(`form/camp/${campCategoryId}/customer/${customerId}`, force);
        return response.data;
    }
    removeCampFormTemplateCache(campCategoryId: number, customerId: number): void {
        this.removeGetCache(`form/camp/${campCategoryId}/customer/${customerId}`);
    }

    async getWeekData(stageId: number, weekId: number, force: boolean = false): Promise<CampWeekData> {
        return await this.getBaseWithCache(`assets/SCamp/Stage${stageId}/Weeks/week${weekId}.json`, force, false, { baseURL: '' }) as any;
    }
    removeWeekDataCache(stageId: number, weekId: number) {
        this.removeGetCache(`assets/SCamp/Stage${stageId}/Weeks/week${weekId}.json`, false);
    }
}
