import { TableServiceBase } from './serviceBase';

export class ContractService extends TableServiceBase<
    Cbas.Models.DTO.ContractListResponse,
    any,
    any>{
    constructor() {
        super('Contracts');
    }

    async getContractsByCustomerId(customerId: number): Promise<Cbas.Models.DTO.ContractResponse[]> {
        const response = await this.getBase(`customer/${customerId}`);
        return response.data;
    }

    async getContractPaymentCurrencys(): Promise<Cbas.Models.DTO.ContractCurrencyResponse[]> {
        const response = await this.getBase('paymentcurrencys');
        return response.data;
    }

    async getContractPaymentMethods(): Promise<Cbas.Models.DTO.ContractPaymentMethodResponse[]> {
        const response = await this.getBase('paymentmethods');
        return response.data;
    }

    async getContractTypes(): Promise<Cbas.Models.DTO.ContractTypeResponse[]> {
        const response = await this.getBase('types');
        return response.data;
    }

    async createPayment(payment: Cbas.Models.ContractPayment): Promise<boolean> {
        const response = await this.postBase('payment/create', payment);
        return response.success;
    }

    async editPayment(payment: Cbas.Models.ContractPayment): Promise<boolean> {
        const response = await this.postBase('payment/edit', payment);
        return response.success;
    }

    async deletePayment(paymentId: number): Promise<boolean> {
        const response = await this.postBase(`payment/delete/${paymentId}`, undefined);
        return response.success;
    }

    async approve(contractId: number): Promise<boolean> {
        const response = await this.postBase(`approve/${contractId}`, undefined);
        return response.success;
    }

    async sign(model: any): Promise<boolean> {
        const response = await this.postBase('sign', model);
        return response.success;
    }

    async getCancelationReasons(): Promise<Cbas.Models.DTO.ContractCancellationReasonResponse[]> {
        const response = await this.getBase('cancellation/reasons');
        return response.data;
    }

    async createCancelation(model: any): Promise<boolean> {
        const response = await this.postBase('cancellation/create', model);
        return response.success;
    }

    async editCancelation(model: any): Promise<boolean> {
        const response = await this.postBase('cancellation/edit', model);
        return response.success;
    }

    async approveCancelation(contractId: number): Promise<boolean> {
        const response = await this.postBase(`cancellation/approve/${contractId}`, undefined);
        return response.success;
    }

    async generateContractPdf(contractId: number): Promise<string> {
        const response = await this.getBase(`pdf/${contractId}`);
        return response.data;
    }

    async generatePaymentPdf(paymentId: number): Promise<string> {
        const response = await this.getBase(`payment/pdf/${paymentId}`);
        return response.data;
    }
}
