import { ServiceBase } from './serviceBase';

export class IARService extends ServiceBase {
    constructor() {
        super('IAR');
    }

    async get(customerId: number): Promise<Cbas.Models.DTO.Report.IARResponse> {
        const response = await this.getBase(customerId);
        return response.data;
    }

    async getChildAssessment(customerId: number): Promise<Cbas.Models.DTO.ChildAssessmentResponse> {
        const response = await this.getBase(`childassessment/${customerId}`);
        return response.data;
    }

    async getParentAssessment(customerId: number): Promise<Cbas.Models.DTO.ParentsAssessmentResponse> {
        const response = await this.getBase(`parentsassessment/${customerId}`);
        return response.data;
    }

    async editChildAssessment(data: Cbas.Models.ChildAssessment): Promise<boolean> {
        const response = await this.postBase('childassessment/edit', data);
        return response.success;
    }

    async editParentAssessment(data: Cbas.Models.ParentsAssessment): Promise<boolean> {
        const response = await this.postBase('parentsassessment/edit', data);
        return response.success;
    }

    async createChildAssessment(data: Cbas.Models.ChildAssessment): Promise<boolean> {
        const response = await this.postBase('childassessment/create', data);
        return response.success;
    }

    async createParentAssessment(data: Cbas.Models.ParentsAssessment): Promise<boolean> {
        const response = await this.postBase('parentsassessment/create', data);
        return response.success;
    }
}
