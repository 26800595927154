import { ServiceBase } from './serviceBase';

export class SettingService extends ServiceBase {
    constructor() {
        super('Settings');
    }

    async getlanguages(): Promise<Cbas.Models.DTO.ChannelCategoryResponse[]> {
        const response = await this.getBase('languages');
        return response.data;
    }

    async getCountries(): Promise<Cbas.Models.DTO.ChannelCategoryResponse[]> {
        const response = await this.getBase('countries');
        return response.data;
    }

    async getChannelCategories(): Promise<Cbas.Models.DTO.ChannelCategoryResponse[]> {
        const response = await this.getBase('channelCategories');
        return response.data;
    }
}
