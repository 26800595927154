import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Layout, Menu } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { RouteItem, calcAdminRoutes } from '@/app.routes';

import styles from './index.less';
import styleVariables from '~themes/vars.less';

import { useAppMmenuCollapsedSelector } from '@store/selectors';
import AppContext from '@/context';
import { TICKET } from '@models/common';
import { storageManager } from '@utils/storage';
import { useLogoConfig } from '@hooks/logoHook';

const getMenuItem = (item: RouteItem): React.ReactNode => {
    if (item.outer) {
        return (
            <Menu.Item icon={item.icon}
                key={item.path}
            >
                <a href={item.path}
                    rel="noreferrer"
                    target="_blank"
                >
                    {item.title}</a>
            </Menu.Item >
        );
    }
    return item.children ?
        (
            <Menu.SubMenu icon={item.icon}
                key={item.path}
                title={item.title}
            >
                {item.children.map(getMenuItem)}
            </Menu.SubMenu>
        ) :
        (
            <Menu.Item icon={item.icon}
                key={item.path}
            >
                <Link to={`/admin/${item.path}`}>
                    {item.title}
                </Link>
            </Menu.Item >
        );
};

let tempParentRouteItem: RouteItem | undefined = undefined;

const AppMenu: React.FC<{ routeItems: RouteItem[] }> = ({ routeItems }) => {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();
    const [openKeys, setOpenKeys] = useState<string[]>();

    const getMenuProps = useCallback((pathname: string, routes: RouteItem[], parent?: RouteItem): void => {
        for (let i in routes) {
            let item = routes[i];
            if (item.children) {
                tempParentRouteItem = item;
                getMenuProps(pathname, item.children, item);
            } else {
                if (`/admin/${item.path}` === pathname) {
                    if (tempParentRouteItem) {
                        setOpenKeys([tempParentRouteItem.path]);
                    }
                    setSelectedKeys([item.path]);
                    break;
                }
            }
        }
        tempParentRouteItem = undefined;
    }, []);

    useEffect(() => {
        getMenuProps(location.pathname, routeItems);
    }, [getMenuProps, location, routeItems]);

    return (
        <Menu className={styles.menu}
            mode="inline"
            onOpenChange={setOpenKeys}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
        >
            {routeItems.map(getMenuItem)}
        </Menu>
    );
};

const Sider: React.FC = () => {
    var menuCollapsed = useAppMmenuCollapsedSelector();
    const { user } = useContext(AppContext);
    const adminRoutes = calcAdminRoutes(user);
    const ticket = storageManager.get<Cbas.Models.DTO.LoginResponse>(TICKET);
    const logoConfig = useLogoConfig(ticket?.user?.studioCode);

    return (
        <Layout.Sider
            breakpoint="lg"
            className={styles.sider}
            collapsed={menuCollapsed}
            collapsible
            theme="light"
            trigger={null}
            width={styleVariables.siderWidth}
        >
            <div className={styles.brand}>
                <div className={styles.logo}>
                    <img alt="logo"
                        className={classnames({ [styles.small]: menuCollapsed })}
                        {...logoConfig.sideLogo}
                    />
                </div>
            </div>
            <div className={styles.menuContainer}>
                <AppMenu routeItems={adminRoutes} />
            </div>
            {!menuCollapsed && (
                <a
                    href="https://docs.qq.com/doc/DV25OSnBQUW5QcG9M"
                    rel="noreferrer"
                    target="_blank"
                >
                    <div className={styles.switchTheme}>
                        <span>
                            <BulbOutlined />
                            <Trans>User Mannul</Trans>
                        </span>
                        {/* <Switch
                        checkedChildren={t`Dark`}
                        unCheckedChildren={t`Light`}
                    /> */}
                    </div>
                </a>
            )}
        </Layout.Sider>
    );
};

export default Sider;
