import React, { Dispatch } from 'react';

import { UserInfo } from '@models/userInfo';

export interface AppContextProps {
    user?: UserInfo;
    setUser?: Dispatch<React.SetStateAction<UserInfo | undefined>>;
}

const AppContext = React.createContext<AppContextProps>({});

export default AppContext;
