import { iaService } from '@services';

import { CbasAction } from '../models';

export const fetchParentSurveyQuestionsAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.iaState.parentSurveyQuestions) {
            const parentSurveyQuestions = await iaService.getParentSurveyQuestions();
            dispatch({ type: 'parentSurveyQuestions', value: parentSurveyQuestions });
        }
    };
};

export const fetchParentAptitudeQuestionsAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.iaState.parentAptitudeQuestions) {
            const parentAptitudeQuestions = await iaService.getParentAptitudeQuestions();
            dispatch({ type: 'parentAptitudeQuestions', value: parentAptitudeQuestions });
        }
    };
};
