import { i18n } from '@lingui/core';
import { en, zh } from 'make-plural/plurals';

import moment from 'moment';
import 'moment/locale/zh-cn';

import { Locale } from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';

import { messages as enMessages } from '../locales/en/messages';
import { messages as zhMessages } from '../locales/zh/messages';

import { storageManager } from './storage';


i18n.loadLocaleData({
    en: { plurals: en },
    zh: { plurals: zh }
});
i18n.load({
    en: enMessages,
    zh: zhMessages
});

export interface Language {
    title: string;
    flag: string;
    momentKey: string;
    antdLocale: Locale;
}

export const languages: Map<string, Language> = new Map<string, Language>([
    [
        'en',
        {
            title: 'English',
            flag: '/images/flags/america.svg',
            momentKey: 'en',
            antdLocale: enUS
        }
    ],
    [
        'zh',
        {
            title: '中文',
            flag: '/images/flags/china.svg',
            momentKey: 'zh-cn',
            antdLocale: zhCN
        }
    ]
]);

export let currentLocale = 'en';
export let currentAntdLocale = enUS;

export const setLanguage = (locale: string) => {
    const langObj = languages.get(locale);
    if (langObj) {
        currentLocale = locale;
        currentAntdLocale = langObj.antdLocale;
        moment.locale(langObj.momentKey);
        i18n.activate(locale);
        storageManager.set('locale', locale);
    }
};


const locale = storageManager.get('locale');

if (locale && languages.has(locale)) {
    setLanguage(locale);
} else {
    setLanguage('en');
}
