import { ServiceBase } from './serviceBase';

export class AccountService extends ServiceBase {
    constructor() {
        super('Account');
    }

    async login(model: Cbas.Models.DTO.LoginRequest): Promise<Cbas.Models.DTO.LoginResponse> {
        const response = await this.postBase('login', model);
        return response.data;
    }

    async changePassword(model: Cbas.Models.DTO.ChangePasswordRequest): Promise<boolean> {
        const response = await this.postBase('changePassword', model);
        return response.success;
    }
}
