import { TableServiceBase } from './serviceBase';

export class ClassService extends TableServiceBase<
    any,
    any,
    any>{
    constructor() {
        super('Classes');
    }
}
