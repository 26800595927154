import { ServiceBase } from './serviceBase';

export class ActivityService extends ServiceBase {
    constructor() {
        super('Activities');
    }

    async create(model: Cbas.Models.CustomerActivity): Promise<boolean> {
        const response = await this.postBase('customer/create', model);
        return response.success;
    }
    async get(customerId: number): Promise<Cbas.Models.DTO.CustomerActivityResponse[]> {
        const response = await this.getBase(`customer/${customerId}`);
        return response.data;
    }
    async getActivities(): Promise<Cbas.Models.DTO.ActivityResponse[]> {
        const response = await this.getBase();
        return response.data;
    }
    async getActivityPurposes(): Promise<Cbas.Models.DTO.ActivityPurposeResponse[]> {
        const response = await this.getBase('purposes');
        return response.data;
    }
    async getActivityPotentialities(): Promise<Cbas.Models.DTO.ActivityPotentialityResponse[]> {
        const response = await this.getBase('potentialities');
        return response.data;
    }
}
