import { campService, contractService, customerService, iarService, prService, semesterService } from '@services';
import { delay } from '@utils/common';

import { ActionInfo, CbasAction } from '../models';

export const delayAction = (): CbasAction => {
    return async () => {
        await delay(100);
    };
};

export const fetchCustomerDetailAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || customerId !== oldState.customerState.customerDetail?.id) {
                // dispatch({ type: 'customerDetail', value: undefined });
                const customer = await customerService.get<Cbas.Models.DTO.CustomerResponse>(customerId, force);
                // await delay(1000);
                dispatch({ type: 'customerDetail', value: customer });
            }
        } else {
            dispatch({ type: 'customerDetail', value: undefined });
        }
    };
};

export const fetchCustomerIARAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || (customerId !== oldState.customerState.customerIAR?.customerId)) {
                // dispatch({ type: 'customerIAR', value: undefined });
                const iar = await iarService.get(customerId);
                // await delay(1000);
                dispatch({ type: 'customerIAR', value: { customerId, data: iar } });
            }
        } else {
            dispatch({ type: 'customerIAR', value: undefined });
        }
    };
};

export const toggleCustomerIAREditTabAction = (value?: string): ActionInfo =>
    ({ type: 'customerIAREditTab', value: value });

export const fetchCustomerIARChildDetailAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || (customerId !== oldState.customerState.customerIARChildDetail?.customerId)) {
                // dispatch({ type: 'customerIARChildDetail', value: undefined });
                const iarChild = await iarService.getChildAssessment(customerId);
                // await delay(1000);
                dispatch({ type: 'customerIARChildDetail', value: { customerId, data: iarChild } });
            }
        } else {
            dispatch({ type: 'customerIARChildDetail', value: undefined });
        }
    };
};

export const fetchCustomerIARParentDetailAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || (customerId !== oldState.customerState.customerIARParentDetail?.customerId)) {
                // dispatch({ type: 'customerIARParentDetail', value: undefined });
                const iarParent = await iarService.getParentAssessment(customerId);
                // await delay(1000);
                dispatch({ type: 'customerIARParentDetail', value: { customerId, data: iarParent } });
            }
        } else {
            dispatch({ type: 'customerIARParentDetail', value: undefined });
        }
    };
};

export const fetchCustomerAttendanceAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || customerId !== oldState.customerState.customerAttendance?.customerId) {
                // dispatch({ type: 'customerAttendance', value: undefined });
                const attendance = await prService.getAttendance(customerId);
                // await delay(100);
                dispatch({ type: 'customerAttendance', value: { customerId, data: attendance } });
            }
        } else {
            dispatch({ type: 'customerAttendance', value: undefined });
        }
    };
};

export const fetchCustomerSemesterReportsAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || customerId !== oldState.customerState.customerSemesterReports?.customerId) {
                // dispatch({ type: 'customerSemesterReports', value: undefined });
                const semesterReports = await semesterService.getSemesterReportsByCustomerId(customerId);
                // await delay(100);
                dispatch({ type: 'customerSemesterReports', value: { customerId, data: semesterReports } });
            }
        } else {
            dispatch({ type: 'customerSemesterReports', value: undefined });
        }
    };
};

export const toggleCustomerSemesterReportEditAction = (value: boolean): ActionInfo =>
    ({ type: 'customerSemesterReportEdit', value: value });

export const fetchCustomerCampReportsAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || customerId !== oldState.customerState.customerCampReports?.customerId) {
                // dispatch({ type: 'customerCampReports', value: undefined });
                const campReports = await campService.getCampReportsByCustomerId(customerId);
                // await delay(100);
                dispatch({ type: 'customerCampReports', value: { customerId, data: campReports } });
            }
        } else {
            dispatch({ type: 'customerCampReports', value: undefined });
        }
    };
};

export const toggleCustomerCampReportEditAction = (value: boolean): ActionInfo =>
    ({ type: 'customerCampReportEdit', value: value });

export const fetchCustomerContractsAction = (customerId?: number, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        if (customerId) {
            const oldState = getState();
            if (force || customerId !== oldState.customerState.customerContracts?.customerId) {
                // dispatch({ type: 'customerContracts', value: undefined });
                const contracts = await contractService.getContractsByCustomerId(customerId);
                // await delay(100);
                dispatch({ type: 'customerContracts', value: { customerId, data: contracts } });
            }
        } else {
            dispatch({ type: 'customerContracts', value: undefined });
        }
    };
};

export const toggleCustomerCurrentContractNoAction = (value?: string): ActionInfo =>
    ({ type: 'customerCurrentContractNo', value: value });

export const toggleCustomerContractEditAction = (value: boolean): ActionInfo =>
    ({ type: 'customerContractEdit', value: value });

export const toggleCustomerContractEditSignAction = (value: boolean): ActionInfo =>
    ({ type: 'customerContractEditSign', value: value });

export const toggleCustomerContractEditCancelationAction = (value: boolean): ActionInfo =>
    ({ type: 'customerContractEditCancelation', value: value });

export const toggleCustomerIarUseLocalLangAction = (value: boolean): ActionInfo =>
    ({ type: 'customerIarUseLocalLang', value: value });
