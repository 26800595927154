import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import styles from './index.less';

/* eslint react/no-did-mount-set-state: 0 */
/* eslint no-param-reassign: 0 */

const isSupportLineClamp = document.body.style.webkitLineClamp !== undefined;

const TooltipOverlayStyle = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word'
};

export const getStrFullLength = (str = '') =>
  str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);

export const cutStrByFullLength = (str = '', maxLength: number) => {
  let showLength = 0;
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1;
    } else {
      showLength += 2;
    }
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, '');
};

const getTooltip = ({ tooltip, overlayStyle, title, children }: any) => {
  if (tooltip) {
    const props = tooltip === true ? { overlayStyle, title } : { ...tooltip, overlayStyle, title };
    return <Tooltip {...props}>{children}</Tooltip>;
  }
  return children;
};

const EllipsisText = ({ text, length, tooltip, fullWidthRecognition, ...other }: any) => {
  if (typeof text !== 'string') {
    throw new Error('Ellipsis children must be string.');
  }
  const textLength = fullWidthRecognition ? getStrFullLength(text) : text.length;
  if (textLength <= length || length < 0) {
    return <span {...other}>{text}</span>;
  }
  const tail = '...';
  let displayText;
  if (length - tail.length <= 0) {
    displayText = '';
  } else {
    displayText = fullWidthRecognition ? cutStrByFullLength(text, length) : text.slice(0, length);
  }

  const spanAttrs = tooltip ? {} : { ...other };
  return getTooltip({
    tooltip,
    overlayStyle: TooltipOverlayStyle,
    title: text,
    children: (
      <span {...spanAttrs}>
        {displayText}
        {tail}
      </span>
    )
  });
};

const Ellipsis: React.FC<any> = ({ children, lines, length, className, tooltip, fullWidthRecognition, ...restProps }: any) => {
  const root = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const node = useRef<HTMLDivElement>(null);
  const shadow = useRef<HTMLDivElement>(null);
  const shadowChildren = useRef<HTMLDivElement>(null);
  const [text, setText] = useState('');
  const [targetCount, setTargetCount] = useState(0);

  const bisection = useCallback((th: number, m: number, b: number, e: number, text: string, shadowNode: HTMLElement): number => {
    const suffix = '...';
    let mid = m;
    let end = e;
    let begin = b;
    shadowNode.innerHTML = text.substring(0, mid) + suffix;
    let sh = shadowNode.offsetHeight;

    if (sh <= th) {
      shadowNode.innerHTML = text.substring(0, mid + 1) + suffix;
      sh = shadowNode.offsetHeight;
      if (sh > th || mid === begin) {
        return mid;
      }
      begin = mid;
      if (end - begin === 1) {
        mid = 1 + begin;
      } else {
        mid = Math.floor((end - begin) / 2) + begin;
      }
      return bisection(th, mid, begin, end, text, shadowNode);
    }
    if (mid - 1 < 0) {
      return mid;
    }
    shadowNode.innerHTML = text.substring(0, mid - 1) + suffix;
    sh = shadowNode.offsetHeight;
    if (sh <= th) {
      return mid - 1;
    }
    end = mid;
    mid = Math.floor((end - begin) / 2) + begin;
    return bisection(th, mid, begin, end, text, shadowNode);
  }, []);

  const computeLine = useCallback(() => {
    if (lines && !isSupportLineClamp) {
      const _text = shadowChildren.current!.innerText || shadowChildren.current!.textContent;
      const lineHeight = parseInt(getComputedStyle(root.current!).lineHeight, 10);
      const targetHeight = lines * lineHeight;
      content.current!.style.height = `${targetHeight}px`;
      const totalHeight = shadowChildren.current!.offsetHeight;
      const shadowNode = shadow.current!.firstChild;

      if (totalHeight <= targetHeight) {
        setText(_text!);
        setTargetCount(_text!.length);
        return;
      }

      // bisection
      const len = text.length;
      const mid = Math.ceil(len / 2);

      const count = bisection(targetHeight, mid, 0, len, text, shadowNode as HTMLElement);

      setText(_text!);
      setTargetCount(count);
    }
  }, [bisection, lines, text]);



  const cls = classNames(styles.ellipsis, className, {
    [styles.lines]: lines && !isSupportLineClamp,
    [styles.lineClamp]: lines && isSupportLineClamp
  });

  useEffect(() => {
    computeLine();
  }, [computeLine, node, lines]);

  if (!lines && !length) {
    return (
      <span className={cls}
          {...restProps}
      >
        {children}
      </span>
    );
  }

  // length
  if (!lines) {
    return (
      <EllipsisText
          className={cls}
          fullWidthRecognition={fullWidthRecognition}
          length={length}
          text={children || ''}
          tooltip={tooltip}
          {...restProps}
      />
    );
  }

  const id = `antd-pro-ellipsis-${`${new Date().getTime()}${Math.floor(Math.random() * 100)}`}`;

  // support document.body.style.webkitLineClamp
  if (isSupportLineClamp) {
    const style = `#${id}{-webkit-line-clamp:${lines};-webkit-box-orient: vertical;}`;

    const node = (
      <div className={cls}
          id={id}
          {...restProps}
      >
        <style>{style}</style>
        {children}
      </div>
    );

    return getTooltip({
      tooltip,
      overlayStyle: TooltipOverlayStyle,
      title: children,
      children: node
    });
  }

  const childNode = (
    <span ref={node}>
      {targetCount > 0 && text.substring(0, targetCount)}
      {targetCount > 0 && targetCount < text.length && '...'}
    </span>
  );

  return (
    <div {...restProps}
        className={cls}
        ref={root}
    >
      <div ref={content}>
        {getTooltip({
          tooltip,
          overlayStyle: TooltipOverlayStyle,
          title: text,
          children: childNode
        })}
        <div className={styles.shadow}
            ref={shadowChildren}
        >
          {children}
        </div>
        <div className={styles.shadow}
            ref={shadow}
        >
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
};



export default Ellipsis;
