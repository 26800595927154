import { AccountService } from './accountService';
import { ActivityService } from './activityService';
import { AppointmentService } from './appointmentService';
import { BehaviourService } from './behaviourService';
import { ClassService } from './classService';
import { CommentService } from './commentService';
import { ContractService } from './contractService';
import { CustomerService } from './customerService';
import { DashboardService } from './dashboardService';
import { FileService } from './fileService';
import { IARService } from './iarService';
import { IAService } from './iaService';
import { LessonCodeService } from './lessonCodeService';
import { LessonService } from './lessonsService';
import { LevelService } from './levelService';
import { PRService } from './prService';
import { SemesterService } from './semesterService';
import { CampService } from './campService';
import { SettingService } from './settingService';
import { StaffService } from './staffService';
import { StudioService } from './studioService';
import { SubjectService } from './subjectService';


export const accountService = new AccountService();
export const activityService = new ActivityService();
export const appointmentService = new AppointmentService();
export const behaviourService = new BehaviourService();
export const classService = new ClassService();
export const contractService = new ContractService();
export const customerService = new CustomerService();
export const iarService = new IARService();
export const iaService = new IAService();
export const lessonService = new LessonService();
export const levelService = new LevelService();
export const prService = new PRService();
export const settingService = new SettingService();
export const staffService = new StaffService();
export const studioService = new StudioService();
export const subjectService = new SubjectService();
export const dashboardService = new DashboardService();
export const lessonCodeService = new LessonCodeService();
export const semesterService = new SemesterService();
export const campService = new CampService();
export const commentService = new CommentService();
export const fileService = new FileService();
