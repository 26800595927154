import { ActionInfo, PageDataState } from '../models';

export const pageDataInitialState: PageDataState = {};

export function pageDataReducer(state: PageDataState = pageDataInitialState, action: ActionInfo): PageDataState {
    const { value } = action;
    switch (action.type) {
        case 'customerPageData':
            return { ...state, customerPageData: value };
        case 'subjectPageData':
            return { ...state, subjectPageData: value };
        default:
            return state;
    }
}
