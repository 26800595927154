import { contractService, lessonCodeService, levelService, semesterService, settingService, staffService, studioService, subjectService } from '@services';

import { CbasAction } from '../models';

export const fetchLanguageDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.languageDdl) {
            const languageDdl = await settingService.getlanguages();
            dispatch({ type: 'languageDdl', value: languageDdl });
        }
    };
};

export const fetchCountryDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.countryDdl) {
            const countryDdl = await settingService.getCountries();
            dispatch({ type: 'countryDdl', value: countryDdl });
        }
    };
};

export const fetchRoleDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.roleDdl) {
            const roleDdl = await staffService.getRoles();
            dispatch({ type: 'roleDdl', value: roleDdl });
        }
    };
};

export const fetchChannelCategoryDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.channelCategoryDdl) {
            const channelCategoryDdl = await settingService.getChannelCategories();
            dispatch({ type: 'channelCategoryDdl', value: channelCategoryDdl });
        }
    };
};

export const fetchTeacherDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.teacherDdl) {
            const teacherDdl = await staffService.getStaffs(6);
            dispatch({ type: 'teacherDdl', value: teacherDdl });
        }
    };
};

export const fetchStudioDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.studioDdl) {
            const studioDdl = await studioService.getStudios();
            dispatch({ type: 'studioDdl', value: studioDdl });
        }
    };
};

export const fetchLevelDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.levelDdl) {
            const levelDdl = await levelService.getLevels();
            dispatch({ type: 'levelDdl', value: levelDdl });
        }
    };
};

export const fetchSubjectDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.subjectDdl) {
            const subjectDdl = await subjectService.getLists();
            dispatch({ type: 'subjectDdl', value: subjectDdl });
        }
    };
};

export const fetchContractPaymentCurrencysDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.contractPaymentCurrencysDdl) {
            const contractPaymentCurrencysDdl = await contractService.getContractPaymentCurrencys();
            dispatch({ type: 'contractPaymentCurrencysDdl', value: contractPaymentCurrencysDdl });
        }
    };
};

export const fetchContractPaymentMethodsDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.contractPaymentMethodsDdl) {
            const contractPaymentMethodsDdl = await contractService.getContractPaymentMethods();
            dispatch({ type: 'contractPaymentMethodsDdl', value: contractPaymentMethodsDdl });
        }
    };
};

export const fetchContractTypesDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.contractTypesDdl) {
            const contractTypesDdl = await contractService.getContractTypes();
            dispatch({ type: 'contractTypesDdl', value: contractTypesDdl });
        }
    };
};

export const fetchLessonCodesDdlAction = (courseCategory: number | undefined, level: number | undefined, subject: number | undefined, force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.lessonCodeDdl) {
            const lessonCodeDdl = await lessonCodeService.getList(courseCategory, level, subject);
            dispatch({ type: 'lessonCodeDdl', value: lessonCodeDdl });
        }
    };
};

export const fetchAssessmentScoreDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.assessmentScoreDdl) {
            const assessmentScoreDdl = await semesterService.getAssessmentScoreList();
            dispatch({ type: 'assessmentScoreDdl', value: assessmentScoreDdl });
        }
    };
};

export const fetchContractCancelationReasonDdlAction = (force: boolean = false): CbasAction => {
    return async (dispatch, getState) => {
        const oldState = getState();
        if (force || !oldState.ddlState.contractCancelationReasonDdl) {
            const contractCancelationReasonDdl = await contractService.getCancelationReasons();
            dispatch({ type: 'contractCancelationReasonDdl', value: contractCancelationReasonDdl });
        }
    };
};
