import React from 'react';
import { Card } from 'antd';
import CountUp from 'react-countup';
import styles from './index.less';

export interface NumberCardProps {
    icon: React.ReactNode;
    color: string;
    title: string;
    number: number;
    countUp?: object;
    style?: React.CSSProperties
}

const NumberCard: React.FC<NumberCardProps> = ({ icon, color, title, number, countUp, style }: NumberCardProps) => {
    return (
        <Card
            bordered={false}
            className={styles.numberCard}
            hoverable
            style={style}
        >
            <div className={styles.content}>
                <p className={styles.title}>{title || 'No Title'}</p>
                <p className={styles.number}>
                    <span className={styles.iconWarp}
                        style={{ color }}
                    >
                        {icon}
                    </span>
                    <CountUp
                        duration={2.75}
                        end={number}
                        separator=","
                        start={0}
                        useEasing
                        {...(countUp || {})}
                    />
                </p>
            </div>
        </Card>
    );
};

export default NumberCard;
