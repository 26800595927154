import { storageManager } from './storage';

export interface Preferences {
    currencyId: number;
    primiaryStudioId: number;
    iarUseLocalLang: boolean;
}

const defaultPreferences: Preferences = {
    currencyId: 1,
    primiaryStudioId: 1,
    iarUseLocalLang: false
};

const storedPreference = storageManager.get<Preferences>('preferences');

export let currentPreferences: Preferences = {
    ...defaultPreferences,
    ...storedPreference
};

export const savePreferences = (preferences: Partial<Preferences>) => {
    currentPreferences = {
        ...currentPreferences,
        ...preferences
    };
    storageManager.set('preferences', currentPreferences);
};
