import { ActionInfo, DdlState } from '../models';

export const ddlInitialState: DdlState = {};

export function ddlReducer(state: DdlState = ddlInitialState, action: ActionInfo): DdlState {
    const { value } = action;
    switch (action.type) {
        case 'languageDdl':
            return { ...state, languageDdl: value };
        case 'countryDdl':
            return { ...state, countryDdl: value };
        case 'roleDdl':
            return { ...state, roleDdl: value };
        case 'channelCategoryDdl':
            return { ...state, channelCategoryDdl: value };
        case 'teacherDdl':
            return { ...state, teacherDdl: value };
        case 'studioDdl':
            return { ...state, studioDdl: value };
        case 'levelDdl':
            return { ...state, levelDdl: value };
        case 'subjectDdl':
            return { ...state, subjectDdl: value };
        case 'contractPaymentCurrencysDdl':
            return { ...state, contractPaymentCurrencysDdl: value };
        case 'contractPaymentMethodsDdl':
            return { ...state, contractPaymentMethodsDdl: value };
        case 'contractTypesDdl':
            return { ...state, contractTypesDdl: value };
        case 'lessonCodeDdl':
            return { ...state, lessonCodeDdl: value };
        case 'assessmentScoreDdl':
            return { ...state, assessmentScoreDdl: value };
        case 'contractCancelationReasonDdl':
            return { ...state, contractCancelationReasonDdl: value };
        default:
            return state;
    }
}
