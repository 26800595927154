import { useSelector } from 'react-redux';
import { CbasState } from '../models';

export const useCustomerDetailSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerDetail);

export const useCustomerIARSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerIAR?.data);

export const useCustomerIARSatusSelector = () =>
    useSelector((state: CbasState) => {
        const hasChildAssessment =
            state.customerState.customerIAR?.data.childAssessment !== null,
            hasParentAssessment =
                state.customerState.customerIAR?.data.parentsAssessment !== null;
        return [hasChildAssessment, hasParentAssessment];
    });

export const useCustomerIAREditTabSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerIAREditTab);

export const useCustomerIARChildDetailSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerIARChildDetail?.data);

export const useCustomerIARParentDetailSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerIARParentDetail?.data);

export const useCustomerIARDetailSelector = () =>
    useSelector((state: CbasState) => ({
        childDetail: state.customerState.customerIARChildDetail?.data,
        parentDetail: state.customerState.customerIARParentDetail?.data
    }));

export const useCustomerAttendanceSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerAttendance?.data);

export const useCustomerSemesterReportsSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerSemesterReports?.data);

export const useCustomerSemesterReportEditSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerSemesterReportEdit);

export const useCustomerCampReportsSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerCampReports?.data);

export const useCustomerCampReportEditSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerCampReportEdit);

export const useCustomerContractsSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerContracts?.data);

export const useCustomerCurrentContractNoSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerCurrentContractNo);

export const useCustomerContractEditSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerContractEdit);

export const useCustomerContractEditSignSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerContractEditSign);

export const useCustomerContractEditCancelationSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerContractEditCancelation);

export const useCustomerIarUseLocalLangSelector = () =>
    useSelector((state: CbasState) => state.customerState.customerIarUseLocalLang);
