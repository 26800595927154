import { ServiceBase } from './serviceBase';

export class IAService extends ServiceBase {
    constructor() {
        super('IA');
    }

    async getParentSurveyQuestions(): Promise<Cbas.Models.DTO.ParentsLearningProfileSurveyQuestionResponse> {
        const response = await this.getBase('parentsurvey/list');
        return response.data;
    }

    async getParentAptitudeQuestions(): Promise<Cbas.Models.DTO.ParentsAptitudeAssessmentQuestionResponse[]> {
        const response = await this.getBase('parentaptitude/list');
        return response.data;
    }
}
