import React, { useContext, useEffect, useState } from 'react';
import { Outlet, RouteObject, useNavigate, useRoutes } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import {
    DashboardOutlined,
    CalendarOutlined,
    TeamOutlined,
    UserSwitchOutlined,
    IdcardOutlined,
    PayCircleOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    SettingOutlined,
    BarChartOutlined,
    AlertOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

import Loadable from '@functions/loadable';

import AppContext from '@/context';
import { UserInfo } from '@models/userInfo';
import { TICKET } from '@models/common';
import { storageManager } from '@utils/storage';

const Index: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/login');
    }, [navigate]);
    return null;
};

export interface RouteItem {
    path: string;
    title?: React.ReactNode;
    icon?: React.ReactNode;
    element?: React.ReactNode;
    children?: RouteItem[];
    outer?: boolean;
}

export const calcAdminRoutes = (user: UserInfo | undefined): RouteItem[] => {
    var tickit = storageManager.get<Cbas.Models.DTO.LoginResponse>(TICKET);
    const items: RouteItem[] = [
        {
            title: <Trans>Dashboard</Trans>,
            path: 'dashboard',
            icon: <DashboardOutlined />,
            element: <Loadable loader={() => import('@pages/admin/dashboard')} />
        }
    ];
    if (user && user.permission <= 4) {
        items.push({
            title: <Trans>Customers</Trans>,
            path: 'customers',
            icon: <UserSwitchOutlined />,
            children: [
                {
                    title: <Trans>Leads</Trans>,
                    path: 'leads',
                    element: <Loadable loader={() => import('@pages/admin/customer/lead')} />
                },
                {
                    title: <Trans>Appointments</Trans>,
                    path: 'appointments',
                    element: <Loadable loader={() => import('@pages/admin/customer/appointment')} />
                },
                {
                    title: <Trans>Assessments</Trans>,
                    path: 'assessments',
                    element: <Loadable loader={() => import('@pages/admin/customer/assessment')} />
                },
                {
                    title: <Trans>Students</Trans>,
                    path: 'students',
                    element: <Loadable loader={() => import('@pages/admin/customer/student')} />
                }
            ]
        });
        items.push({
            title: <Trans>Contracts</Trans>,
            path: 'contracts',
            icon: <PayCircleOutlined />,
            element: <Loadable loader={() => import('@pages/admin/contract')} />
        });
    };
    if (user && user.permission === 5) {
        items.push({
            title: <Trans>Customers</Trans>,
            path: 'customers',
            icon: <UserSwitchOutlined />,
            children: [
                {
                    title: <Trans>Leads</Trans>,
                    path: 'leads',
                    element: <Loadable loader={() => import('@pages/admin/customer/lead')} />
                },
                {
                    title: <Trans>Appointments</Trans>,
                    path: 'appointments',
                    element: <Loadable loader={() => import('@pages/admin/customer/appointment')} />
                },
                {
                    title: <Trans>Assessments</Trans>,
                    path: 'assessments',
                    element: <Loadable loader={() => import('@pages/admin/customer/assessment')} />
                },
                {
                    title: <Trans>Students</Trans>,
                    path: 'students',
                    element: <Loadable loader={() => import('@pages/admin/customer/student')} />
                }
            ]
        });
    }
    if (user && user.permission === 6) {
        items.push({
            title: <Trans>Students</Trans>,
            path: 'students',
            icon: <UserSwitchOutlined />,
            element: <Loadable loader={() => import('@pages/admin/customer/student')} />
        });
    }
    if (user && user.permission === 8) {
        items.push({
            title: <Trans>Leads</Trans>,
            path: 'leads',
            icon: <UserSwitchOutlined />,
            element: <Loadable loader={() => import('@pages/admin/customer/lead')} />
        });
    }
    if (user && user.permission === 7) {
        items.push({
            title: <Trans>Customers</Trans>,
            path: 'customers',
            icon: <UserSwitchOutlined />,
            children: [
                {
                    title: <Trans>Leads</Trans>,
                    path: 'leads',
                    element: <Loadable loader={() => import('@pages/admin/customer/lead')} />
                },
                {
                    title: <Trans>Appointments</Trans>,
                    path: 'appointments',
                    element: <Loadable loader={() => import('@pages/admin/customer/appointment')} />
                },
                {
                    title: <Trans>Assessments</Trans>,
                    path: 'assessments',
                    element: <Loadable loader={() => import('@pages/admin/customer/assessment')} />
                }
            ]
        });
    }
    if (user && user.permission <= 6) {
        items.push({
            title: <Trans>Classes</Trans>,
            path: 'classes',
            icon: <TeamOutlined />,
            element: <Loadable loader={() => import('@pages/admin/class')} />
        });
    }
    if (user && (user.permission <= 4 || user.permission === 6)) {
        items.push({
            title: <Trans>Schedule</Trans>,
            path: 'scheduleLessons',
            icon: <CalendarOutlined />,
            element: <Loadable loader={() => import('@pages/admin/scheduleLesson')} />
        });
    }
    if (user && (user.permission <= 4)) {
        items.push({
            title: <Trans>Staff</Trans>,
            path: 'staff',
            icon: <IdcardOutlined />,
            element: <Loadable loader={() => import('@pages/admin/staff')} />
        });
        if (process.env.REACT_APP_REPORT_URL) {
            items.push(
                {
                    title: <Trans>Reports</Trans>,
                    path: process.env.REACT_APP_REPORT_URL + '?t=' + (tickit?.token?.accessToken ?? '') + '&e=' + (tickit?.token?.expired ?? ''),
                    icon: <BarChartOutlined />,
                    outer: true
                }
            );
        }
    }
    if (user && user.permission <= 2) {
        items.push({
            title: <Trans>Studios</Trans>,
            path: 'studios',
            icon: <ApartmentOutlined />,
            element: <Loadable loader={() => import('@pages/admin/studio')} />
        });
    }
    return items;
};

export const v22 = [
    {
        title: <Trans>Academic Settings</Trans>,
        path: 'academicSettings',
        icon: <AppstoreOutlined />,
        children: [
            {
                title: <Trans>Subjects</Trans>,
                path: 'subjects',
                element: <Loadable loader={() => import('@pages/admin/academicSetting/subject')} />
            },
            {
                title: <Trans>Levels</Trans>,
                path: 'levels',
                element: <Loadable loader={() => import('@pages/admin/academicSetting/level')} />
            },
            {
                title: <Trans>Behaviours</Trans>,
                path: 'behaviours',
                element: <Loadable loader={() => import('@pages/admin/academicSetting/behaviour')} />
            }
        ]
    },
    {
        title: <Trans>Reports Settings</Trans>,
        path: 'reportsSettings',
        icon: <SettingOutlined />,
        children: [
            {
                title: <Trans>Initial Assessment</Trans>,
                path: 'assessmentAssessment',
                element: <Loadable loader={() => import('@pages/admin/reportsSetting/initialAssessment')} />
            },
            {
                title: <Trans>English Skills</Trans>,
                path: 'englishSkills',
                element: <Loadable loader={() => import('@pages/admin/reportsSetting/englishSkill')} />
            },
            {
                title: <Trans>Level Summary</Trans>,
                path: 'levelSummary',
                element: <Loadable loader={() => import('@pages/admin/reportsSetting/levelSummary')} />
            },
            {
                title: <Trans>Interest Codes</Trans>,
                path: 'interestCodes',
                element: <Loadable loader={() => import('@pages/admin/reportsSetting/interestCode')} />
            }
        ]
    },
    {
        title: <Trans>Announcements</Trans>,
        path: 'announcements',
        icon: <AlertOutlined />,
        element: <Loadable loader={() => import('@pages/admin/announcement')} />
    },
    {
        title: <Trans>FAQ</Trans>,
        path: 'faq',
        icon: <QuestionCircleOutlined />,
        element: <Loadable loader={() => import('@pages/admin/faq')} />
    }
];

export const calcRouteItems = (user: UserInfo | undefined): RouteItem[] => [
    {
        path: '/',
        element: <Index />
    },
    {
        path: 'login',
        element: <Loadable loader={() => import('@pages/login')} />
    },
    {
        path: 'admin',
        element: <Loadable loader={() => import('@pages/admin')} />,
        children: calcAdminRoutes(user)
    },
    {
        path: '*',
        element: <Loadable loader={() => import('@pages/404')} />
    }
];

const getRoute = (item: RouteItem): RouteObject => {
    return {
        path: item.element ? item.path : '',
        element: item.element ?? <Outlet />,
        children: item.children?.map(getRoute)
    };
};

const AppRoutes: React.FC = () => {
    const { user } = useContext(AppContext);
    const [routes, setRoutes] = useState<RouteObject[]>([]);
    useEffect(() => {
        setRoutes(calcRouteItems(user).map(getRoute));
    }, [user]);
    return useRoutes(routes);
};

export default AppRoutes;
