import { TableServiceBase } from './serviceBase';

export class BehaviourService extends TableServiceBase<
    Cbas.Models.DTO.BehaviourListResponse,
    any,
    any>{
    constructor() {
        super('Behaviours');
    }
}
