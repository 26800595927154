import React from 'react';
import classnames from 'classnames';

import styles from './index.less';

export interface GlobalFooterProps {
    className?: any;
    links?: Array<any>;
    copyright: any;
}

const GlobalFooter: React.FC<GlobalFooterProps> = ({ copyright, className, links }: GlobalFooterProps) => {
    const clsString = classnames(styles.globalFooter, className);
    return (
        <footer className={clsString}>
            {links && (
                <div className={styles.links}>
                    {links.map(link => (
                        <a
                            href={link.href}
                            key={link.key}
                            rel="noreferrer"
                            target={link.blankTarget ? '_blank' : '_self'}
                            title={link.key}
                        >
                            {link.title}
                        </a>
                    ))}
                </div>
            )}
            {copyright && <div className={styles.copyright}>{copyright}</div>}
        </footer>
    );
};

export default GlobalFooter;
