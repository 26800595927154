import { TableServiceBase } from './serviceBase';

export class FileService extends TableServiceBase<
    any,
    any,
    any> {
    constructor() {
        super('Files');
    }

    async upload(file: any): Promise<string> {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        };
        const response = await this.postBase('upload', file, config);
        return response.data.filename;
    }
}
