import { UserInfo } from '@models/userInfo';

enum Roles {
    Admin = 1,
    RA = 2,
    Principal = 3,
    Director = 4,
    LC = 5,
    LT = 6,
    AB = 7,
    TMK = 8
}
export const getUserPermission = (user: Cbas.Models.DTO.StaffLoginResponse): number => {
    const permission = Roles[user.role as any];
    if (!permission) {
        return 99;
    }
    return parseInt(permission);
};

export const getUser = (user: Cbas.Models.DTO.StaffLoginResponse): UserInfo => {
    return {
        ...user,
        permission: getUserPermission(user)
    };
};
