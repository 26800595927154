import { ServiceBase } from './serviceBase';

export class PRService extends ServiceBase {
    constructor() {
        super('PR');
    }

    async getAttendance(customerId: number): Promise<Cbas.Models.DTO.Report.PRAttendanceResponse> {
        const response = await this.getBase(`attendance/${customerId}`);
        return response.data;
    }

    async getAptitude(customerId: number): Promise<Cbas.Models.DTO.Report.PRAptitudeChartResponse[]> {
        const response = await this.getBase(`aptitude/${customerId}`);
        return response.data;
    }

    async getBehaviour(customerId: number): Promise<Cbas.Models.DTO.Report.PRBehaviourScaleResponse> {
        const response = await this.getBase(`behaviour/${customerId}`);
        return response.data;
    }
}
