import { applyMiddleware, combineReducers, createStore } from 'redux';
import { useDispatch } from 'react-redux';
import thunk from 'redux-thunk';

import { CbasDispatch, CbasState } from './models';
import { appReducer, pageDataReducer, customerReducer, ddlReducer, iaReducer } from './reducers';

export const useCbasDispatch = () => useDispatch<CbasDispatch>();

const reducers = combineReducers<CbasState>({
    appState: appReducer,
    pageDataState: pageDataReducer,
    customerState: customerReducer,
    ddlState: ddlReducer,
    iaState: iaReducer
});

export const store = createStore(reducers, applyMiddleware(thunk));
