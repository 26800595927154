import React from 'react';
import ReactLoadable from 'react-loadable';

import { Loading } from '@components/loader';

const Loadable: React.FC<{ loader: () => Promise<any> }> = ({ loader }) => {
    const LoadableComponent = ReactLoadable({
        loader,
        loading: Loading
    });
    return <LoadableComponent />;
};

export default Loadable;
