import { StudioLogoConfig } from '@models/studioLogoConfig';
import { studioService } from '@services';
import { defaultLogoConfig } from '@services/studioService';
import { useEffect, useState } from 'react';

export const useLogoConfig = (studio?: string) => {
  const [logoConfig, setLogoConfig] = useState<StudioLogoConfig>(defaultLogoConfig);
  useEffect(() => {
    studioService.getLogoConfig().then(res => {
      var config = res.find(c => c.studio === studio);
      setLogoConfig(config ?? defaultLogoConfig);
    });
  }, [studio]);
  return logoConfig;
};
