import { currentPreferences, savePreferences } from '@utils/preferences';
import { ActionInfo, CustomerState } from '../models';

export const customerInitialState: CustomerState = {
    customerSemesterReportEdit: false,
    customerCampReportEdit: false,
    customerContractEdit: false,
    customerContractEditSign: false,
    customerContractEditCancelation: false,
    customerIarUseLocalLang: currentPreferences.iarUseLocalLang
};

export function customerReducer(state: CustomerState = customerInitialState, action: ActionInfo): CustomerState {
    const { value } = action;
    switch (action.type) {
        case 'customerDetail':
            return { ...state, customerDetail: value };
        case 'customerIAR':
            return { ...state, customerIAR: value };
        case 'customerIAREditTab':
            return { ...state, customerIAREditTab: value };
        case 'customerIARChildDetail':
            return { ...state, customerIARChildDetail: value };
        case 'customerIARParentDetail':
            return { ...state, customerIARParentDetail: value };
        case 'customerAttendance':
            return { ...state, customerAttendance: value };
        case 'customerContracts':
            return { ...state, customerContracts: value };
        case 'customerCurrentContractNo':
            return { ...state, customerCurrentContractNo: value };
        case 'customerContractEdit':
            return { ...state, customerContractEdit: value };
        case 'customerContractEditSign':
            return { ...state, customerContractEditSign: value };
        case 'customerContractEditCancelation':
            return { ...state, customerContractEditCancelation: value };
        case 'customerSemesterReports':
            return { ...state, customerSemesterReports: value };
        case 'customerSemesterReportEdit':
            return { ...state, customerSemesterReportEdit: value };
        case 'customerCampReports':
            return { ...state, customerCampReports: value };
        case 'customerCampReportEdit':
            return { ...state, customerCampReportEdit: value };
        case 'customerIarUseLocalLang':
            savePreferences({
                iarUseLocalLang: value
            });
            return { ...state, customerIarUseLocalLang: value };
        default:
            return state;
    }
}
