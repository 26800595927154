import { currentLocale } from '@utils/languages';
import { ActionInfo, AppState } from '../models';

export const appInitialState: AppState = {
  language: currentLocale,
  loadingDisplay: 'none',
  menuCollapsed: false
};

export function appReducer(state: AppState = appInitialState, action: ActionInfo): AppState {
  const { value } = action;
  switch (action.type) {
    case 'language':
      return { ...state, language: value };
    case 'loading':
      return { ...state, loadingDisplay: value };
    case 'menu':
      return { ...state, menuCollapsed: value };
    case 'downloading':
      return { ...state, downloading: value };
    default:
      return state;
  }
}
